const generateStoreLink = ({storeId, companyName, logoUrl, redirect_url, skipLink}) => {
    const params = new URLSearchParams()
    params.append('id', storeId)

    if (companyName) {
        params.append('name', companyName)
    }

    if (logoUrl) {
        params.append('logo', logoUrl)
    }

    if (redirect_url) {
        params.append('redirect_url', redirect_url)
    }

    if (skipLink) {
        params.append('skip_link', skipLink)
    }

    return '/store/link?' + params.toString()
}

export {
    generateStoreLink
}