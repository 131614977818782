// extracted by mini-css-extract-plugin
export const space = "header-module--space--9vA1u";
export const appIcon = "header-module--appIcon--9sX2T";
export const portalLink = "header-module--portalLink--xfQhT";
export const plainBut = "header-module--plainBut--+8EVh";
export const highlight = "header-module--highlight--JZXYd";
export const popover = "header-module--popover--GQzsN";
export const divider = "header-module--divider--HOe36";
export const header = "header-module--header--Q1pKg";
export const loginBut = "header-module--loginBut---CH2s";
export const profileButton = "header-module--profileButton--MDY8M";
export const profileTxt = "header-module--profileTxt--g3vHz";
export const profileLink = "header-module--profileLink--gtuqb";
export const meunItem = "header-module--meunItem--ZTjNE";
export const licenseTxt = "header-module--licenseTxt--eIQP2";
export const categoryBar = "header-module--categoryBar--cmnQZ";
export const menuIcon = "header-module--menuIcon--P40xw";
export const list = "header-module--list--hy6oc";