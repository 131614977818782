import React from 'react'
import cn from 'classnames'
import { Card } from '@material-ui/core'
import FillButton from 'components/presenter/buttons/FillButton'
import MaxCashbackReward from 'components/presenter/text/MaxCashbackReward'
import { LoginDialog, useLoginDialog } from '../LoginDialog'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import { useAuthStore } from 'stores'
import * as styles from './styles.module.css'
import { generateStoreLink } from 'libs/affiliate'

const CompanyCard = ({className, affiliateData}) => {
    const { t } = useTranslation()
    const authStore = useAuthStore()
    const { open, handleClickOpen, onClose, onLogin } = useLoginDialog()
    const companyName = affiliateData?.company?.name
    const logoUrl = affiliateData?.company?.company_logo?.url

    const storeLink = React.useMemo(() => {
        return generateStoreLink({storeId: affiliateData._id, companyName, logoUrl})
    })

    const handleClick = (event) => {
        event.preventDefault()
        if (authStore.isLoggedIn()) {
            window.open(storeLink, '_blank')
        } else {
            handleClickOpen()
        }
    }

    return (
        <>
            <Card className={cn(className, styles.card)}>
                <img className={styles.logo} src={logoUrl} />
                <span className={styles.txtName}>{companyName}</span>
                <MaxCashbackReward rewards={affiliateData.rewards} />
                <FillButton
                    className={cn(styles.btn, 'mui-btn-link')}>
                    <Link
                        to={storeLink}
                        onClick={handleClick}
                    >
                        {t('affiliate.shopNow')}
                    </Link>
                </FillButton>
            </Card>
            <LoginDialog 
                open={open}
                onClose={onClose}
                onLogin={onLogin}
            />
        </>
        
    )
}

export default React.memo(CompanyCard)