import React from 'react'
import PlatButton from 'components/presenter/buttons/PlatButton'
import cn from 'classnames'
import { 
  AppBar, 
  Toolbar,
  // IconButton, 
  Popover,
  Fade, 
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Divider,
  Switch
} from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import PaymentIcon from '@material-ui/icons/Payment'
import AssessmentIcon from '@material-ui/icons/Assessment'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'
// import MenuIcon from '@material-ui/icons/Menu'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import TranslateIcon from '@material-ui/icons/Translate'
import LanguageSelect from 'components/container/nav/LanguageSelect'
import { useThemeMode } from 'components/presenter/theme/themeMode'
import SearchBar from 'components/presenter/form/SearchBar'
import PopupSearch from 'components/presenter/form/PopupSearch'
import AppIcon from 'assets/svg/app_icon.inline.svg'
import FillButton from 'components/presenter/buttons/FillButton'
import { Trans, useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next'
import { useAuthStore, useUserStore } from 'stores'

// import CategoryHeader from 'components/container/nav/CategoryHeader'
import { useSearchBar } from 'app/hook/searchHook'

import * as styles from './header.module.css'


const MenuList = ({handleClose}) => {
  const { t } = useTranslation()
  const { navigate } = useI18next()
  const authStore = useAuthStore()

  const logout = React.useCallback(async () => {
    handleClose()
    const res = await authStore.logout()
    if (!res.error) {
      navigate('/')
    }
  })

  if (!
    authStore.isLoggedIn()) {
    return (
    <>
      <ListItem className={styles.meunItem}>
        <ListItemIcon>
          <TranslateIcon className={styles.meunItem} />
        </ListItemIcon>
        <LanguageSelect 
          onChange={handleClose} />
      </ListItem>
    </>)
  }

  return (
    <>
      <Link className={cn(styles.profileLink)} to="/report/">
        <ListItem button className={styles.meunItem} onClick={handleClose}>
          <ListItemAvatar>
            <AssessmentIcon className={styles.meunItem} />
          </ListItemAvatar>
          <ListItemText primary={t('common.cashbackReport')} />
        </ListItem>
      </Link>
      <Link className={cn(styles.profileLink)} to="/invoice/">
        <ListItem button className={styles.meunItem} onClick={handleClose}>
          <ListItemAvatar>
            <AccountBalanceWalletIcon className={styles.meunItem} />
          </ListItemAvatar>
          <ListItemText primary={t('common.invoice')} />
        </ListItem>
      </Link>
      <Link className={cn(styles.profileLink)} to="/campaign-pass/">
        <ListItem button className={styles.meunItem} onClick={handleClose}>
          <ListItemAvatar>
            <ConfirmationNumberIcon className={styles.meunItem} />
          </ListItemAvatar>
          <ListItemText primary={t('common.campaignPass')} />
        </ListItem>
      </Link>
      <Link className={cn(styles.profileLink)} to="/payment/">
        <ListItem button className={styles.meunItem} onClick={handleClose}>
          <ListItemAvatar>
            <PaymentIcon className={styles.meunItem} />
          </ListItemAvatar>
          <ListItemText primary={t('common.payment')} />
        </ListItem>
      </Link>
      <ListItem className={styles.meunItem}>
        <ListItemIcon>
          <TranslateIcon className={styles.meunItem} />
        </ListItemIcon>
        <LanguageSelect 
          onChange={handleClose} />
      </ListItem>
      <Divider variant="middle" component="li" className={styles.divider} />
      <ListItem button onClick={logout} className={styles.meunItem}>
        <ListItemIcon>
          <ExitToAppIcon className={styles.meunItem} />
        </ListItemIcon>
        <ListItemText primary={t('common.loginout')} />
      </ListItem>
    </>
  )
  
}

const LoginNav = ({location}) => {
  const { t } = useTranslation()
  const authStore = useAuthStore()
  const { pathname } = location

  if (!
    authStore.isLoggedIn()) {
    return (
    <>
    </>)
  }

  return (
    <div className='deskTopOnly'>
      <PlatButton
        className={cn(styles.plainBut, 'mui-btn-link', pathname.includes('/report') ? styles.highlight : '')}>
        <Link
          to='/report/'>
          {t('common.cashbackReport')}
        </Link>
      </PlatButton>
      <PlatButton
        className={cn(styles.plainBut, 'mui-btn-link', pathname.includes('/payment') ? styles.highlight : '')}>
        <Link
          to='/payment/'>
          {t('common.payment')}
        </Link>
      </PlatButton>
    </div>
  )
}

const Header = ({location}) => {
  const { t } = useTranslation()
  const { navigate, language } = useI18next()
  const authStore = useAuthStore()
  const userStore = useUserStore()
  const {themeMode, toggleThemeMode} = useThemeMode()
  

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isShowOverlay, setIsShowOverlay] = React.useState(false)
  const {searchData, onTextChange, handleOnSearch } = useSearchBar(location)
  
  const [userProfile, setUserProfile] = React.useState(null)

  const handleClick = React.useCallback((event) => {
    setAnchorEl(event.currentTarget)
  })

  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  })

  const renderSearchBarComp = () => (
      <>
        <SearchBar
          className='deskTopOnly'
          {...searchData}
          onSearch={handleOnSearch}
          onTextChange={onTextChange}
        />
        <SearchBar
          className='mobileOnly'
          classes={{
            location: 'deskTopOnly'
          }}
          {...searchData}
          isReadOnly
          onClick={() => {setIsShowOverlay(true)}}
        />
      </>
    )

  React.useEffect(() => {
    setUserProfile(userStore.getUserProfile())
  })
  
  const name = !userProfile ? '' : userProfile.name 
  const url = !userProfile || !userProfile.profile_image ? null : userProfile.profile_image.url

  return (
    <>
      <AppBar position="sticky" className={cn(styles.header)}>
        {/* <CategoryHeader /> */}
        <Toolbar>
          <AppIcon className={cn(styles.appIcon)} alt={t('common.appName')} onClick={() => navigate('/')} />
          {/* <IconButton className={styles.menuIcon} edge="start" color="inherit" onClick={onMenuClick}>
            <MenuIcon />
          </IconButton> */}
          {/* {renderSearchBarComp()} */}
          {/* <LoginNav location={location} /> */}
          <div className={cn(styles.space)} />
          {
            !authStore.isLoggedIn() ?
              <FillButton
                className={cn(styles.btnPromote, 'mui-btn-link')}>
                <Link
                  to='/login/'>
                  {t('login.login')}
                </Link>
              </FillButton>
            : <></>
          }          
          <PlatButton
            aria-describedby="popup-menu"
            onClick={handleClick}>
            <div className={styles.profileButton}>
              {
                authStore.isLoggedIn() 
                ? <Avatar alt={name} src={url} />
                : <SettingsIcon className={styles.meunItem} />
              }
              {/* <Avatar alt={name} src={url} /> */}
              {/* <span className={styles.profileTxt}>{name}</span> */}
            </div>
          </PlatButton>
          <Popover
            id="popup-menu"
            className={styles.popover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <List component="nav" className={styles.list}>
              {
                 authStore.isLoggedIn() ?
                 <>
                  <Link className={cn(styles.profileLink)} to="/profile/">
                    <ListItem button alignItems="flex-start" onClick={handleClose}>
                      <ListItemAvatar>
                        <Avatar alt={name} src={url} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={name}
                        secondary={t('mainMenu.viewYourProfile')}
                      />
                    </ListItem>
                  </Link>
                  <Divider variant="middle" component="li" className={styles.divider} />
                 </>
                 : <></>
              }
              {/* <ListItem className={styles.meunItem}>
                <ListItemIcon>
                  <Brightness4Icon className={styles.meunItem} />
                </ListItemIcon>
                <ListItemText primary={t('mainMenu.darkMode')} />
                <Switch
                  className='mui-switch'
                  checked={themeMode === 'dark'}
                  onClick={toggleThemeMode}
                  name="darkMode"
                  inputProps={{ 'aria-label': 'Dark mode' }}
                />
              </ListItem> */}
              <MenuList handleClose={handleClose} />
              <ListItem className={cn(styles.meunItem, styles.licenseTxt)}>
                <ListItemText primary={
                  <Trans
                  i18nKey="mainMenu.license"
                  components={{
                    term: <Link to='/terms/' onClick={handleClose} />,
                    privacy: <Link to='/privacy/' onClick={handleClose} />,
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    contact: <a href="mailto:hello@coyns.co" />,
                  }}
                /> } />
              </ListItem>
            </List>
          </Popover>
        </Toolbar>
      </AppBar>
      {
        isShowOverlay ?
        <PopupSearch
          location={location}
          onClose={() => setIsShowOverlay(false)}/> :
        <></>
      }
      
    </>
  )
}
export default Header